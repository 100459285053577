<template>
  <div class="grid">
    <div v-html="getMainContent(content)" class="collection__main-content col-12 col-m-5" />

    <div class="col-m-2"></div>
    <div class="collection__main-content-video col-12 col-m-5" v-if="hasVideo(content)">
      <SfVideo
        class="collection__video desktop-only"
        :src="getDesktopVideo(content)"
        :poster="getDesktopVideoPoster(content)"
      />
      <SfVideo
        class="collection__video mobile-only"
        :src="getMobileVideo(content)"
        :poster="getMobileVideoPoster(content)"
      />
    </div>
  </div>
</template>

<script type="ts">
import { defineComponent } from '@nuxtjs/composition-api';
import { SfVideo } from '~/components';
import collectionGetters from '~/modules/catalog/category/getters/collectionGetters';

export default defineComponent({
  components: {
    SfVideo,
  },
  props: {
    content: {
      type: Object,
      default: null,
    },
  },
  setup() {

    return {
      ...collectionGetters
    }
  }
});
</script>

<style lang="scss" scoped>
.collection {

  &__item-image {
        @include for-mobile {
      margin: var(--spacer-lg) 0;
    }
  }

  &__main {

    @include for-desktop {
      margin-bottom: var(--spacer-xl);
    }

    &-content {
      display: flex;
      flex-direction: column;

      ::v-deep {

        .collection__main-content-image {
          margin: var(--spacer-xs) 0 var(--spacer-lg);
        }

        p {
          font-size: var(--font-size--base);
          line-height: var(--line-height--base);
          margin: 0 0 var(--spacer-base);

          @include for-desktop {
            font-size: var(--font-size--lg);
            line-height: var(--line-height--lg);
          }
        }

        img {
          display: block;
          width: 100%;
          max-width: 100%;
        }

        .bottom {
          margin-top: auto;
        }

        .right {
          margin-left: auto;
        }
      }
    }

    &-content-video {
      @include for-mobile {
        margin: var(--spacer-xs) 0 var(--spacer-xs);
      }
    }
  }
}
</style>
